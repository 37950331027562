import React from 'react';
import styled from 'styled-components';
import { Block, FileInput, HtmlEditor, Text, TextArea, TextInput } from '../../../../layout';

function Element({
    type,
    path,
    title,
    value,
    properties = {},
    errors = {},
    onChange,
    onBlur
}) {
    const getElement = () => {
        switch (type) {
            case "TextLine":
                return (
                    <Block width={456}>
                        <TextInput label="Value" name={`${path}`} value={value || ""} error={errors[path]} type={properties.type || "text"} onChange={onChange} onBlur={onBlur} />
                    </Block>
                );
            case "TextMultiLine":
                return (
                    <Block width={456}>
                        <Block>
                            <TextArea label="Value" name={`${path}`} value={value && (value || "")} error={errors[path]} onChange={onChange} onBlur={onBlur} />
                        </Block>
                    </Block>
                );
            case "TextEditor":
                return (
                    <Block width={456}>
                        <Block>
                            <HtmlEditor label="Value" name={`${path}`} value={value && (value || "")} error={errors[path]} onBlur={onBlur} />
                        </Block>
                    </Block>
                );
            case "File":
                return (
                    <Block width={456}>
                        <Block>
                            <FileInput label="Value" name={`${path}`} value={value} error={errors[path]} onBlur={onBlur} />
                        </Block>
                    </Block>
                );
            case "Button":
                return (
                    <Block width={456}>
                        <Block>
                            <TextInput label="Title" name={`${path}.title`} value={value && (value.title || "")} error={errors[`${path}.title`]} onChange={onChange} onBlur={onBlur} />
                        </Block>
                        <Block mt={12}>
                            <TextInput label="Url" name={`${path}.url`} value={value && (value.url || "")} error={errors[`${path}.url`]} onChange={onChange} onBlur={onBlur} />
                        </Block>
                    </Block>
                );
            default:
                break;
        }
    }

    return (
        <Wrapper color="white">
            <Block>
                <Block pl={24} pr={24} pt={16} pb={16}>
                    <Text size={14} height={20} mb={16}>{title}</Text>
                    <Block row>
                        {getElement()}
                    </Block>
                </Block>
            </Block>
        </Wrapper>
    );
}

const Wrapper = styled(Block)`
    position: relative;
`;

export default Element;