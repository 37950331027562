import * as React from "react"

function IconButton(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2 8a6 6 0 1112 0A6 6 0 012 8z" stroke="#35383B" />
      <path d="M14 14L8 8" stroke="#35383B" strokeLinejoin="round" />
      <path d="M8 11V8h3" stroke="#35383B" />
    </svg>
  )
}

export default IconButton
