import * as React from "react"

function IconSearch(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={7} cy={6} r={4} stroke="#5D6368" />
            <path d="M10 9l3.5 4" stroke="#5D6368" />
        </svg>
    )
}

export default IconSearch
