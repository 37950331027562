import * as React from "react"

function IconTextMultiLine(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 5H2M10 8H2M13 11H2"
        stroke="#35383B"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconTextMultiLine
