import { gql, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Block, Button, Text, TextInput } from '../../components/layout';
import { SvgLogo } from '../../svgs';
import { getClientToken, setClientToken } from '../../utils/client';
import validateFormSchema from '../../utils/validateFormSchema';

function Login() {
    const [cookies, setCookie] = useCookies(["backoffice_session"]);
    const history = useHistory();

    const [login, { loading }] = useMutation(
        gql`
            mutation userLogin($email: String!, $password: String!) {
                userLogin(email: $email, password: $password) {
                    token
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data.userLogin) {
                    setClientToken(data.userLogin.token);
                    setCookie("backoffice_session", data.userLogin.token, { path: '/' });
                }
            }
        }
    );

    if (getClientToken() || cookies.backoffice_session) {
        history.push("/dashboard");
        return null;
    }

    return (
        <Block color="greyDarker" width="100%" height="100%" center middle>
            <Block color="white" width={420} height={516} pl={44} pr={44}>
                <Block mt={52} row middle>
                    <SvgLogo />
                </Block>
                <Block mt={64}>
                    <Text size={24} height={28}>Hello, welcome back</Text>
                    <Text size={12} height={15} mt={4} color="grey">Insert your credentials to login into your account</Text>
                </Block>
                <Block mt={32}>
                    <Formik
                        initialValues={{}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    email: yup.string().email().required(),
                                    password: yup.string().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            login({ variables: values });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Block>
                                        <Block>
                                            <TextInput name="email" label="E-mail" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.email} />
                                        </Block>
                                        <Block mt={12}>
                                            <TextInput name="password" label="Password" type="password" onChange={handleChange} onBlur={handleBlur} error={errors.password} />
                                        </Block>
                                        <Block mt={12}>
                                            <Text size={12} height={16} color="greyLight" underline pointer>Forgot password?</Text>
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Login" loading={loading} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Block>
        </Block >
    );
}

export default Login;
