import React, { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Block, Text } from '../../../../components/layout';
import { SvgIconContentTypes, SvgIconDown, SvgIconImages, SvgIconLogout, SvgIconNews, SvgIconPages, SvgIconSettings, SvgIconTemplates, SvgIconUsers, SvgLogoWhite } from '../../../../svgs';
import colors from '../../../../utils/colors';
import { store } from '../../../../utils/store';

export default function Sidebar() {
    const { setLanguage, languages, language, user } = useContext(store);
    const [cookies, setCookie, removeCookie] = useCookies(["backoffice_session", "backoffice_language"]);
    const [isLanguagesOpen, setIsLanguagesOpen] = useState(false);

    return (
        <Block width={220} height="100%" color="greyDark" style={{ minWidth: 220 }}>
            <Block center middle mt={24}>
                <SvgLogoWhite />
            </Block>
            <Block mt={76} flex>
                <Link to="/dashboard/pages" activeClassName="selected">
                    <Block row center ml={40}>
                        <Block>
                            <SvgIconPages />
                        </Block>
                        <Text size={14} height={32} color="grey" ml={14}>Pages</Text>
                    </Block>
                </Link>
                {/* <Link to="/dashboard/articles" activeClassName="selected">
                    <Block row center ml={40}>
                        <Block>
                            <SvgIconNews />
                        </Block>
                        <Text size={14} height={32} color="grey" ml={14}>Articles</Text>
                    </Block>
                </Link> */}
                <Link to="/dashboard/products" activeClassName="selected">
                    <Block row center ml={40}>
                        <Block>
                            <SvgIconNews />
                        </Block>
                        <Text size={14} height={32} color="grey" ml={14}>Products</Text>
                    </Block>
                </Link>
                <Link to="/dashboard/users" activeClassName="selected">
                    <Block row center ml={40}>
                        <Block>
                            <SvgIconUsers />
                        </Block>
                        <Text size={14} height={32} color="grey" ml={14}>Users</Text>
                    </Block>
                </Link>
                <Link to="/dashboard/files" activeClassName="selected">
                    <Block row center ml={40}>
                        <Block>
                            <SvgIconImages />
                        </Block>
                        <Text size={14} height={32} color="grey" ml={14}>Files</Text>
                    </Block>
                </Link>
                {user?.role !== "role.administrator" &&
                    <Link to="/dashboard/templates" activeClassName="selected">
                        <Block row center ml={40}>
                            <Block>
                                <SvgIconTemplates />
                            </Block>
                            <Text size={14} height={32} color="grey" ml={14}>Templates</Text>
                        </Block>
                    </Link>
                }
                <Link to="/dashboard/content-types" activeClassName="selected">
                    <Block row center ml={40}>
                        <Block>
                            <SvgIconContentTypes />
                        </Block>
                        <Text size={14} height={32} color="grey" ml={14}>Content Types</Text>
                    </Block>
                </Link>
                <Link to="/dashboard/settings" activeClassName="selected">
                    <Block row center ml={40}>
                        <Block>
                            <SvgIconSettings />
                        </Block>
                        <Text size={14} height={32} color="grey" ml={14}>Settings</Text>
                    </Block>
                </Link>
            </Block>
            {user && <Block mb={24} pl={40} pr={40}>
                <Text size={18} height={24} color="white">{user?.name}</Text>
                <Text size={12} height={24} color="greyLight" transform='capitalize'>{user?.role?.split('.')[1]}</Text>
            </Block>}
            {language && <Block column>
                {isLanguagesOpen && languages.map((language) =>
                    <Block mb={1} onClick={() => { setLanguage(language); setIsLanguagesOpen(false) }}><Language row center pointer><Text size={12} height={16} color="white">{language.name} ({language.code.toUpperCase()})</Text></Language></Block>
                )}
                <Language row center space="between" pointer onClick={() => setIsLanguagesOpen(!isLanguagesOpen)}>
                    <Text size={12} height={16} color="white">{language.name} ({language.code.toUpperCase()})</Text>
                    <SvgIconDown color="white" />
                </Language>
            </Block>}
            <Block color="greyDarker" row center pointer height={52} pl={16} pr={16} onClick={() => {
                removeCookie("backoffice_session", { path: '/' });
                window.location.reload(false);
            }}>
                <SvgIconLogout />
                <Text size={14} height={20} color="white" ml={12}>Logout</Text>
            </Block>
        </Block>
    );
}

const Link = styled(NavLink)`
    margin-top: 12px;
    text-decoration: none;

    &.selected span {
        color: white !important;
    }

    &.selected svg * {
        stroke: white;
    }
`;

const Language = styled(Block)`
    height: 48px;
    background-color: ${colors.grey};
    padding: 0 18px;

    :hover {
        background-color: ${colors.greyDark}
    }
`;