
export const errorTranslator = (error) => {
    switch (error) {
        case 'error.unauthorized': return 'Unauthorized';
        case 'error.forbidden': return 'Forbidden';
        case 'error.resource_not_found': return 'Resource not found';
        case 'error.email_already_exists': return 'Email already exists';
        case 'error.network_error': return 'Can\'t connect to server';
        default: return error;
    }
}

export function deepEqual(object1 = {}, object2 = {}) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            areObjects && !deepEqual(val1, val2) ||
            !areObjects && val1 !== val2
        ) {
            return false;
        }
    }

    return true;
}

function isObject(object) {
    return object != null && typeof object === 'object';
}