import * as React from "react"

function IconFile(props) {
    return (
        <svg
            width={60}
            height={80}
            viewBox="0 0 60 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M0 80h60V25L35 0H0v80z" fill="#F5F5F5" />
            <path d="M35 25h25L35 0v25z" fill="#35383B" />
        </svg>
    )
}

export default IconFile
