import * as React from "react"

function IconClose({ color = "#fff", ...props }) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11.358 4.891L4.641 11.61M11.358 11.609L4.641 4.89"
                stroke={color}
            />
        </svg>
    )
}

export default IconClose
