import * as React from "react"

function IconEdit(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M10.364 3l2.122 2.121-6.364 6.364L3.5 12l.5-2.636L10.363 3z"
                stroke="#35383B"
            />
            <path d="M13 14H2" stroke="#35383B" strokeLinejoin="round" />
        </svg>
    )
}

export default IconEdit
