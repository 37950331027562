import * as React from "react"

function IconUp(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M4 9l4-2.667L12 9" stroke="#35383B" />
        </svg>
    )
}

export default IconUp
