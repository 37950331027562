import * as React from "react"

function IconDown({ color = "#35383B", ...props }) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M12 7L8 9.667 4 7" stroke={color} />
        </svg>
    )
}

export default IconDown
