import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import { Block } from '../../components/layout';
import { ArticleEdit, ContentTypeEdit, PageEdit } from '../../pages';
import useAuth from '../../utils/useAuth';

function Editor() {
    return (
        <Block row flex width="100%" height="100vh" color="greyAlmostWhite" style={{ overflow: "hidden" }}>
            <Switch>
                <Route exact path="/editor/page/:pageId" component={PageEdit} />
                <Route exact path="/editor/content-type/:contentTypeId" component={ContentTypeEdit} />
                <Route exact path="/editor/article/:articleId" component={ArticleEdit} />
                <Route
                    exact
                    path="/editor"
                    render={() => <Redirect to="/dashboard" />}
                />
            </Switch>
        </Block>
    );
}

export default useAuth(Editor);