import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import { Block } from '../../components/layout';
import { TemplateEdit } from '../../pages';
import useAuth from '../../utils/useAuth';

function Builder() {
    return (
        <Block row flex width="100%" height="100vh" color="greyAlmostWhite" style={{ overflow: "hidden" }}>
            <Switch>
                <Route exact path="/builder/template/:templateId" component={TemplateEdit} />
                <Route
                    exact
                    path="/builder"
                    render={() => <Redirect to="/dashboard" />}
                />
            </Switch>
        </Block>
    );
}

export default useAuth(Builder);