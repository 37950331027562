import { convertToRaw, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import draftToHtml from 'draftjs-to-html';
import { Field } from "formik";
import React, { useState } from "react";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled, { css } from 'styled-components';
import colors from "../../../utils/colors";
import Block from '../Block';
import Text from '../Text';

const Component = ({ label, name, value, setFieldValue, onBlur }) => {
    const [editorState, setEditorState] = useState(EditorState.createWithContent(stateFromHTML(value || "")));

    return (
        <Input
            placeholder={label}
            editorState={editorState}
            onBlur={onBlur}
            onEditorStateChange={(editorState) => {
                setEditorState(editorState);
                const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
                setFieldValue(name, html);
            }}
        />
    )
}

function HtmlEditor(
    {
        label,
        name,
        error,
        onBlur,
    }
) {
    return (
        <BlockInput>
            <Field name={name}>
                {({ field: { value }, form: { setFieldValue } }) => {
                    return (<Component
                        label={label}
                        name={name}
                        value={value}
                        setFieldValue={setFieldValue}
                        onBlur={onBlur}
                    />)
                }}
            </Field>
            {error &&
                <Block mt={4}>
                    <Text
                        size={10}
                        height={12}
                        color="greyDark"
                    >
                        {error}
                    </Text>
                </Block>
            }
        </BlockInput>
    );
}

export default React.memo(HtmlEditor);

const BlockInput = styled(Block)`
    position: relative;
`;

const Input = styled(Editor)`
    /* background-color: ${colors.greyNotWhite};
    color: ${colors.greyDark};
    font-size: 14px;
    line-height: 24px;
    font-family: "Medium";
    border: 0;
    padding: 20px 16px 8px;
    transition: all 0.4s ease-in-out;
    border-bottom: 1px solid #B2B6B9;
    border-radius: 0;
    :focus{
        border-color: ${colors.greyDark};
    }
    ${({ error }) => error && css`
        border-color: #FF0505;
        color: #FF0505;
    `} */
`;