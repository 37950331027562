import { FieldArray } from "formik";
import React from "react";
import { Block, Button, FileInput } from "../../../../components/layout";

function ImagesInput(
    {
        name,
        label,
        errors,
        onBlur,
        onChange,
        value = []
    }
) {
    return (
        <>
            <FieldArray
                name={name}
                render={arrayHelpers => (
                    <>
                        {value && value.length > 0 && (
                            value.map((item, index) => (
                                <Block key={index}>
                                    <Block mt={12}>
                                        <FileInput name={`${name}[${index}]`} value={item} error={errors && errors[`${name}[${index}]`]} label={label} onChange={onChange} onBlur={onBlur} />
                                    </Block>
                                    <Block mt={12}>
                                        <Button type="button" text="Remove" onClick={() => arrayHelpers.remove(index)} />
                                    </Block>
                                </Block>
                            ))
                        )}
                        <Block mt={12}>
                            <Button type="button" text="Add more" onClick={() => arrayHelpers.push(null)} />
                        </Block>
                    </>
                )}
            />
        </>
    );
}

export default React.memo(ImagesInput);