import { gql, useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { getClientToken, setClientToken } from './client';
import { store } from './store';

export default (Component) => {
    return () => {
        const { setUser, user } = useContext(store);
        const [cookies] = useCookies(['backoffice_session']);
        const history = useHistory();

        const [queryUserMe] = useLazyQuery(
            gql`
                query userMe {
                    userMe {
                        id
                        name
                        email
                        role
                    }
                }
            `,
            {
                onError: () => {
                    history.push("/login");
                },
                onCompleted: (data) => {
                    if (data && data.userMe) {
                        setUser(data.userMe);
                    }
                }
            }
        );

        if (cookies.backoffice_session) {
            setClientToken(cookies.backoffice_session);
        }

        useEffect(() => {
            if (!user && getClientToken()) {
                queryUserMe();
            }
        }, []);

        if (getClientToken()) {
            if (!user) {
                return null;
            }
            return <Component />
        } else {
            history.push("/login");
        }

        return null;
    }
}