import * as React from "react"

function IconImage(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2 14h12V2H2v12zM2.5 13.5c1-3.167 7.5-6 11 0" stroke="#35383B" />
      <circle cx={10.5} cy={5.5} r={1.5} stroke="#35383B" />
    </svg>
  )
}

export default IconImage
