import * as React from "react"

function IconModalDelete(props) {
    return (
        <svg
            width={42}
            height={36}
            viewBox="0 0 42 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M41 35L21 1 1 35h40zM21 13v14M21 29v2" stroke="#35383B" />
        </svg>
    )
}

export default IconModalDelete
