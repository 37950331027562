import { Field } from "formik";
import React from "react";
import styled, { css } from 'styled-components';
import Block from '../Block';
import Text from '../Text';

function CheckBox(
  {
    name,
    label,
    error
  }
) {
  return (
    <Field name={name}>
      {({ field: { value }, form: { setFieldValue } }) => {
        return (
          <Block flex row center onClick={() => setFieldValue(name, !value)}>
            <LabelCheck>
              <StyledCheckbox value={value} error={error}>
                <InnerBox />
              </StyledCheckbox>
            </LabelCheck>
            {label &&
              <Block ml={8}>
                <Text
                  size={12}
                  height={16}
                  color={value ? "#35383B" : "#B2B6B9"}
                  style={{ transition: 'all 0.4s ease-in-out' }}
                >
                  {label}
                </Text>
              </Block>
            }
          </Block>
        );
      }}
    </Field>
  );
}

export default React.memo(CheckBox);

const LabelCheck = styled.label`
  height: 12px;
  width: 12px;
`;
const InnerBox = styled.div`
  width: 8px;
  height: 8px;
  background: #35383B;
  opacity: 0;
  transition: all 0.4s ease-in-out;
`;
const TextInput = styled(Text)`
  color: #B2B6B9;
  transition: all 0.4s ease-in-out;
`;
const StyledCheckbox = styled.div`
  background: #F5F5F5;
  border: 1px solid #B2B6B9;
  width:12px;
  height:12px;
  background:transparent;
  display:flex;
  align-items:center;
  justify-content: center;
  transition:all 0.4s ease-in-out;
  :hover{
    border-color: #35383B;
    cursor: pointer;
  }
  ${({ error }) => error && css`
    ${({ value }) => !value && css`
      border-color: #FF0505;
    `};
  `};
  ${({ value }) => value && css`
    border-color: #35383B;
    ${InnerBox}{
      opacity: 1;
    }
    ${TextInput}{
      color: #35383B;
    }
  `};
`;