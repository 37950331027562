import React, { useState } from 'react';
import styled from 'styled-components';
import { SvgIconDown, SvgIconUp } from '../../../../../svgs';
import { Block, Text } from '../../../../layout';

function Section({
    title,
    children
}) {
    const [isChildrenOpen, setIsChildrenOpen] = useState(false);

    return (
        <Wrapper>
            <Header row center space="between" mt={12} mb={12}>
                <Text size={24} height={32} bold>{title}</Text>
                <Block row>
                    <Block pointer onClick={() => setIsChildrenOpen(!isChildrenOpen)}>
                        {isChildrenOpen ? <SvgIconUp /> : <SvgIconDown />}
                    </Block>
                </Block>
            </Header>
            <Block width="100%" height={1} color="greyLight" mb={8} />
            <Children open={isChildrenOpen}>
                {children}
            </Children>
        </Wrapper>
    );
}

const Wrapper = styled(Block)`
    position: relative;
`;

const Children = styled(Block)`
    overflow: ${({ open }) => open ? "visible" : "hidden"};
    height: ${({ open }) => open ? "auto" : "0px"};
`;

const Header = styled(Block)`
    position: relative;
`;

export default Section;