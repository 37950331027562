import * as React from "react"

function IconTextLine(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M13.5 3.5v2h-11v-2h11z" stroke="#35383B" />
            <path d="M14 12H2M10 9H2" stroke="#35383B" strokeLinejoin="round" />
        </svg>
    )
}

export default IconTextLine
