import { useContext } from 'react';
import { store } from './store';

export default ({ roles = [], children }) => {
    const { user } = useContext(store);

    if (!user) {
        return null;
    }

    if (!!roles && roles.length > 0 && !roles.includes(user.role)) {
        return null;
    }

    return (children);
}