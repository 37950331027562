import React, { createContext, useState } from 'react';

const store = createContext();
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [language, setLanguage] = useState(null);

  return <Provider value={
    {
      user, setUser,
      languages, setLanguages,
      language, setLanguage
    }
  }>{children}</Provider>;
};

export { store, StateProvider };
