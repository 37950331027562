import cuid from 'cuid';
import { FieldArray, Formik } from 'formik';
import React from 'react';
import { Block } from '../../layout';
import { ButtonAddElement, Element, List, Section } from './components';

function TemplateBuilder({ structure, onSubmit, bindSubmitForm }) {
    const initialValues = {
        sections: [
            {
                id: cuid(),
                title: "Section Name",
                key: "sectionkey",
                properties: {
                    fields: []
                }
            }
        ]
    }

    const getElement = (type) => {
        switch (type) {
            case "List": return List;
            default: return Element;
        }
    }

    return (
        <Block width={580}>
            <Formik
                initialValues={structure.sections && structure.sections.length > 0 ? structure : initialValues}
                validateOnChange={false}
                validateOnBlur={true}
                validate={values => {
                    const errors = {};
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    onSubmit && onSubmit(values);
                }}
            >
                {({
                    values: template,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    submitForm
                }) => {
                    bindSubmitForm && bindSubmitForm(submitForm);
                    return (
                        <form onSubmit={handleSubmit}>
                            <FieldArray
                                name="sections"
                                render={sectionArrayHelpers => (
                                    template.sections.map((section, sectionIndex) => {
                                        return (
                                            <Block key={section.id}>
                                                <Section
                                                    path={`sections[${sectionIndex}]`}
                                                    sectionIndex={sectionIndex}
                                                    title={section.title}
                                                    sectionKey={section.key}
                                                    onMoveUp={() => {
                                                        sectionArrayHelpers.move(sectionIndex, sectionIndex - 1);
                                                    }}
                                                    onMoveDown={() => {
                                                        sectionArrayHelpers.move(sectionIndex, sectionIndex + 1);
                                                    }}
                                                    onDelete={() => {
                                                        if (template.sections.length <= 1) {
                                                            return;
                                                        }

                                                        sectionArrayHelpers.remove(sectionIndex);
                                                    }}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                >
                                                    <FieldArray
                                                        name={`sections[${sectionIndex}].properties.fields`}
                                                        render={(arrayHelpers) => {
                                                            if (section.properties && section.properties.fields.length === 0) {
                                                                return (
                                                                    <ButtonAddElement showSection onElementSelected={(type) => {
                                                                        if (type === "Section") {
                                                                            sectionArrayHelpers.insert(sectionIndex + 1, {
                                                                                id: cuid(),
                                                                                title: "New Section",
                                                                                key: "newSection",
                                                                                properties: {
                                                                                    fields: []
                                                                                }
                                                                            });
                                                                            return;
                                                                        }

                                                                        arrayHelpers.insert(0, {
                                                                            id: cuid(),
                                                                            type,
                                                                            title: `${type} Title`,
                                                                            key: `${type}Key`,
                                                                        })
                                                                    }} />
                                                                );
                                                            }

                                                            if (section.properties) {
                                                                return (
                                                                    section.properties.fields.map((field, fieldIndex) => {
                                                                        const Element = getElement(field.type);
                                                                        return (
                                                                            <Block key={field.id} mt={12}>
                                                                                <Element
                                                                                    path={`sections[${sectionIndex}].properties.fields[${fieldIndex}]`}
                                                                                    title={field.title}
                                                                                    fieldKey={field.key}
                                                                                    type={field.type}
                                                                                    properties={field.properties}
                                                                                    getElement={getElement}
                                                                                    onMoveUp={() => {
                                                                                        arrayHelpers.move(fieldIndex, fieldIndex - 1);
                                                                                    }}
                                                                                    onMoveDown={() => {
                                                                                        arrayHelpers.move(fieldIndex, fieldIndex + 1);
                                                                                    }}
                                                                                    onDelete={() => {
                                                                                        arrayHelpers.remove(fieldIndex);
                                                                                    }}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                />
                                                                                <ButtonAddElement showSection={section.properties.fields.length - 1 === fieldIndex} onElementSelected={(type) => {
                                                                                    if (type === "Section") {
                                                                                        sectionArrayHelpers.insert(sectionIndex + 1, {
                                                                                            id: cuid(),
                                                                                            title: "New Section",
                                                                                            key: "newSection",
                                                                                            properties: {
                                                                                                fields: []
                                                                                            }
                                                                                        });
                                                                                        return;
                                                                                    }

                                                                                    arrayHelpers.insert(fieldIndex + 1, {
                                                                                        id: cuid(),
                                                                                        type,
                                                                                        title: `${type} Title`,
                                                                                        key: `${type}Key`,
                                                                                    });
                                                                                }} />
                                                                            </Block>
                                                                        );
                                                                    })
                                                                );
                                                            }

                                                        }}
                                                    />
                                                </Section>
                                            </Block>
                                        )
                                    })
                                )}
                            />
                        </form>
                    )
                }}
            </Formik>
        </Block>
    );
}

export default TemplateBuilder;