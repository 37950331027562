import React from 'react';
import styled from 'styled-components';
import { Block } from '../../../../components/layout';
import { SvgIconSearch } from '../../../../svgs';
import colors from '../../../../utils/colors';

function Search({ onChange }) {
    return (
        <Block style={{ position: "relative" }}>
            <Block style={{ position: "absolute", left: 20, top: 18 }}>
                <SvgIconSearch />
            </Block>
            <Input type="text" placeholder="Search" onChange={onChange} />
        </Block>
    );
}

const Input = styled.input`
    font-family: "Medium";
    height: 52px;
    width: 320px;
    border: none;
    padding-left: 44px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-right: 1px solid ${colors.greyAlmostWhite} ;
`;

export default Search;