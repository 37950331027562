import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Header, Modal, Table } from '../../components';
import { Search } from '../../components/Header/components';
import { Block, Button, Text, TextInput } from '../../components/layout';
import ButtonAction from '../../components/Table/components/ButtonAction';
import { SvgIconEdit, SvgIconModalDelete, SvgIconTrash } from '../../svgs';
import HasScope from '../../utils/HasScope';
import validateFormSchema from '../../utils/validateFormSchema';

function Templates() {
    const history = useHistory();
    const paginate = useRef(false);
    const [search, setSearch] = useState("");
    const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(null);

    const [result, setResult] = useState({ edges: [], pageInfo: { hasNextPage: false }, totalCount: 0 });

    const [queryTemplates, { loading }] = useLazyQuery(
        gql`
            query templates($first: Int, $after: String, $search: String) {
                templates(first: $first, after: $after, search: $search) {
                    edges {
                        node {
                            id
                            name
                            structure
                        }
                        cursor
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                    totalCount
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.templates) {
                    setResult({
                        edges: paginate.current ? [...result.edges, ...data.templates.edges] : data.templates.edges,
                        pageInfo: data.templates.pageInfo,
                        totalCount: data.templates.totalCount
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const [mutationTemplateCreate, { loading: loadingTemplateCreate }] = useMutation(
        gql`
            mutation templateCreate($name: String!, $structure: Json!) {
                templateCreate(name: $name, structure: $structure) {
                    id
                    name
                    structure
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.templateCreate) {
                    history.push(`/builder/template/${data.templateCreate.id}`);
                }
            }
        }
    );

    const [mutationTemplateDelete, { loading: loadingTemplateDelete }] = useMutation(
        gql`
            mutation templateDelete($id: ID!) {
                templateDelete(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.templateDelete) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.templateDelete.id);
                    if (index !== -1) {
                        result.edges.splice(index, 1);
                        setModalDeleteIsOpen(null);
                        setResult([...data]);
                    }
                }
            }
        }
    );

    const requestData = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        queryTemplates({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined
            }
        });
    }

    useEffect(() => {
        requestData();
    }, [search]);

    return (
        <>
            <Block flex height="100%">
                <Header
                    leftChildren={<Search onChange={(e) => setSearch(e.target.value)} />}
                    rightChildren={<HasScope roles={["role.developer"]}><Button style={{ minWidth: 309 }} text="Add template" onClick={() => setModalCreateIsOpen(true)} /></HasScope>}
                />
                <Block height="100%" mr={20} ml={20} style={{ overflow: "hidden" }}>
                    <Table
                        loading={loading}
                        columns={[
                            { label: "Name", key: "name" }
                        ]}
                        rows={result.edges.map((edge) => ({ ...edge.node, actions: <HasScope roles={["role.developer"]}><ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => history.push(`/builder/template/${edge.cursor}`)} /><ButtonAction icon={<SvgIconTrash />} onClick={() => setModalDeleteIsOpen(edge.cursor)} /></HasScope> }))}
                        next={() => requestData({ next: true, after: result.pageInfo.endCursor })}
                        hasMore={result.pageInfo.hasNextPage}
                    />
                </Block>
            </Block>
            <Modal
                title="Create template"
                isOpen={modalCreateIsOpen}
                onRequestClose={() => setModalCreateIsOpen(false)}
            >
                <Text size={14} height={20}>Templates</Text>
                <Block mt={28}>
                    <Formik
                        initialValues={{}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationTemplateCreate({ variables: { name: values.name, structure: {} } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Block>
                                        <Block>
                                            <TextInput name="name" label="Name" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.name} />
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingTemplateCreate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>
            <Modal
                width={280}
                title="Delete template"
                isOpen={!!modalDeleteIsOpen}
                onRequestClose={() => setModalDeleteIsOpen(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this template?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this template.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingTemplateDelete} onClick={() => mutationTemplateDelete({ variables: { id: modalDeleteIsOpen } })} />
                </Block>
            </Modal>
        </>
    );
}

export default Templates;