import { SnackbarProvider } from 'notistack';
import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { Dashboard, Editor, Login } from './layouts';
import Builder from './layouts/Builder';
import { SnackbarUtilsConfigurator } from './utils/toast';

function App() {
    return (
        <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
            <SnackbarUtilsConfigurator />
            <Router>
                <Switch>
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/builder" component={Builder} />
                    <Route path="/editor" component={Editor} />
                    <Route exact path="/login" component={Login} />
                    <Redirect exact from="/" to="login" />
                </Switch>
            </Router>
        </SnackbarProvider>
    );
}

export default App;