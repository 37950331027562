import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Header, TemplateBuilder } from '../../components';
import { Block, Button, Text } from '../../components/layout';
import { SvgIconArrowLeft } from '../../svgs';
import toast from '../../utils/toast';

function TemplateEdit() {
    const history = useHistory();
    let { templateId } = useParams();
    const [data, setData] = useState(null);

    let submitForm = null;

    const [queryTemplate, { loading }] = useLazyQuery(
        gql`
            query template($id: ID!) {
                template(id: $id) {
                    id
                    name
                    structure
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.template) {
                    setData(data.template);
                }
            }
        }
    );

    const [mutationTemplateUpdate, { loading: loadingTemplateUpdate }] = useMutation(
        gql`
            mutation templateUpdate($id: ID!, $name: String, $structure: Json) {
                templateUpdate(id: $id, name: $name, structure: $structure) {
                    id
                    name
                    structure
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                toast.success(`Saved`);
            }
        }
    );

    useEffect(() => {
        queryTemplate({ variables: { id: templateId } });
    }, []);

    return (
        <>
            <Block flex height="100%">
                <Header
                    leftChildren={
                        <Block height="100%" row>
                            <Block width={52} height={52} center middle pointer onClick={() => history.push("/dashboard/templates")}>
                                <SvgIconArrowLeft />
                            </Block>
                            <Block width={1} height="100%" color="greyAlmostWhite" />
                            <Block width={229} height="100%" middle>
                                {data && <Text size={18} height={32} ml={24}>{data.name}</Text>}
                            </Block>
                            <Block width={1} height="100%" color="greyAlmostWhite" />
                        </Block>
                    }
                    rightChildren={
                        <Block row>
                            <Button style={{ minWidth: 309 }} text="Save" onClick={() => submitForm()} loading={loadingTemplateUpdate} />
                        </Block>
                    }
                />
                <Block flex pl={230} pt={64} style={{ overflow: "auto" }}>
                    {data && <TemplateBuilder
                        onSubmit={(values) => mutationTemplateUpdate({ variables: { id: templateId, structure: values } })}
                        bindSubmitForm={(method) => submitForm = method}
                        structure={data.structure}
                    />}
                </Block>
            </Block>
        </>
    );
}

export default TemplateEdit;