import * as React from "react"

function IconPages(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M2 11h7V2H2v9z" stroke="#5D6368" />
            <path d="M9.5 5H13v9H6v-2.7" stroke="#5D6368" />
        </svg>
    )
}

export default IconPages
