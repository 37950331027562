import React from "react";
import { Block, Text } from "../../../../components/layout";
import { SvgIconEdit, SvgIconFile, SvgIconTrash } from "../../../../svgs";
import colors from "../../../../utils/colors";

export default function FileSelectItem({ name, mimetype, url, onClickUpdate, onClickDelete }
) {
    const preview = () => {
        if (mimetype.includes("image")) {
            return (<img src={url} style={{ width: "100%", height: "100%", objectFit: "cover" }} />);
        } else if (mimetype.includes("video")) {
            return (
                <video width="100%" height="100%" controls>
                    <source src={url} type={mimetype} />
                    Your browser does not support the video tag.
                </video>
            );
        } else {
            return (<Block width="100%" height="100%" center middle><SvgIconFile /></Block>);
        }
    }

    return (
        <Block center color="white" width={180} ml={10} mr={10} mb={10}>
            <Block width={156} height={136} mt={12} ml={12} mr={12} mb={4}>
                {preview()}
            </Block>
            <Text singleLine size={12} height={20} pl={16} pr={16}>{name}</Text>
            <Block color="greyAlmostWhite" height={1} width="100%" mt={8} />
            <Block width="100%" height={52} row color="white">
                <Block pointer flex height={52} center middle row onClick={onClickUpdate}>
                    <SvgIconEdit />
                    <Text size={14} height={20}>Edit</Text>
                </Block>
                <Block pointer width={52} height={52} center middle row onClick={onClickDelete} style={{ borderLeft: `1px solid ${colors.greyAlmostWhite}` }}>
                    <SvgIconTrash />
                </Block>
            </Block>
        </Block>
    );
}