import * as yup from 'yup';

export default (schema, values) => {
    const errors = {};

    try {
        schema.validateSync(values, {
            abortEarly: false
        });
    } catch (err) {
        if (err instanceof yup.ValidationError) {
            err.inner.forEach(error => {
                errors[error.path] = error.message;
            });
        }
    }

    return errors;
}