import * as React from "react"

function LogoWhite(props) {
  return (
    <svg
      width={96}
      height={27}
      viewBox="0 0 96 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0)" fill="#fff">
        <path d="M41.3 17.144h5.447v-2.672H38.31V27h2.99v-4.928h4.484v-2.671H41.3v-2.257zM69.708 14.472h-2.99V27h2.99V14.472zM52.465 7.76L50.033 0h-2.991l3.93 12.528h2.989L57.89 0H54.9l-2.435 7.76zM52.724 17.144l.967-2.671H50.7L46.167 27h2.99l.775-2.142h3.942L54.629 27h2.99l-3.475-9.856h-1.42zM50.822 22.4l1.107-3.058 1.08 3.058h-2.187zM77.733 20.625l-2.887-6.153h-2.991V27h2.99v-6.155l1.392 2.966h2.99l1.392-2.966V27h2.99V14.472h-2.99l-2.886 6.153zM93.01 27h2.991l-4.417-12.528h-2.502L84.55 27h2.991l.775-2.142h3.941L93.01 27zm-3.807-4.6l1.108-3.058L91.39 22.4h-2.187zM59.162 14.472h-3.159v2.672h3.159V27h2.99v-9.856h3.16v-2.672h-6.15zM40.393 0h-3.158v2.671h3.158v9.857h2.99V2.671h3.16V0h-6.15zM27.123.531C21.41-1.596 17 3.385 17 3.385S12.593-1.597 6.88.528c-10.136 3.77-9.01 16.947 2.975 25.908l.013-.001v-.35l.001-1.82c0-.203.166-.366.369-.366.204 0 .368.165.368.365v.78l1.99.002.002-4.717v-.83c0-.201.165-.364.368-.364.204 0 .368.164.368.364v.513l1.927.001.002-6.726h.424l.002-4.305h.342c.038-1.04.404-1.63.582-1.856-.16-.088-.263-.225-.262-.38 0-.22.206-.404.486-.459-.068-.031-.114-.079-.114-.134 0-.058.051-.11.127-.14v-.8h-.41V4.98h.41v-.467h.31v.467h.41v.233h-.41v.8c.077.03.127.082.127.14 0 .055-.045.103-.114.134.28.055.486.24.486.459 0 .155-.104.292-.263.38.177.225.543.815.581 1.856h.343v4.305h.424v6.726h1.927V19.5c0-.201.164-.364.368-.364s.368.163.368.364V25.047h1.99v-.78c0-.202.164-.365.368-.365s.368.163.368.364V26.439C36.127 17.482 37.257 4.304 27.123.53z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h96v27H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LogoWhite
