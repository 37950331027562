import * as React from "react"

function IconTextEditor(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 5H4" stroke="#35383B" strokeLinejoin="round" />
      <path d="M4 2H2v12h2M12 14h2V2h-2" stroke="#35383B" />
      <path d="M9 8H4M11 11H4" stroke="#35383B" strokeLinejoin="round" />
    </svg>
  )
}

export default IconTextEditor
