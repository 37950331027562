import React from "react";
import { SvgIconCheck, SvgIconFile } from "../../../../../svgs";
import Block from '../../../Block';
import Text from '../../../Text';

export default function FileSelectItem({ name, mimetype, url, selected, onSelect }
) {
    const preview = () => {
        if (mimetype.includes("image")) {
            return (<img src={url} style={{ width: "100%", height: "100%", objectFit: "cover" }} />);
        } else if (mimetype.includes("video")) {
            return (
                <video width="100%" height="100%" controls>
                    <source src={url} type={mimetype} />
                    Your browser does not support the video tag.
                </video>
            );
        } else {
            return (<Block width="100%" height="100%" center middle><SvgIconFile /></Block>);
        }
    }

    return (
        <Block center color="white" width={180} height={240} ml={10} mr={10} mt={10} mb={10}>
            <Block width={156} height={136} mt={12} ml={12} mr={12} mb={4}>
                {preview()}
            </Block>
            <Text singleLine size={12} height={20} pl={16} pr={16}>{name}</Text>
            <Block color="greyAlmostWhite" height={1} width="100%" mt={8} />
            <Block pl={8} pr={8} pt={8} width="100%">
                <Block pointer color={selected ? "greyDarker" : "greyAlmostWhite"} width="100%" height={44} center row space="between" pl={16} pr={16} onClick={onSelect}>
                    <Text size={14} height={28} color={selected ? "white" : "greyLight"}>Selected</Text>
                    {selected && < SvgIconCheck />}
                </Block>
            </Block>
        </Block>
    );
}