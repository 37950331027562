import React from 'react';
import { Block } from '../../components/layout';

function Header({ leftChildren, rightChildren }) {
    return (
        <Block height={52} row space="between" color="white">
            <Block>
                {leftChildren}
            </Block>
            <Block>
                {rightChildren}
            </Block>
        </Block>
    );
}

export default Header;