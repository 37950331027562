import { gql, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { Header, Modal, Table } from '../../components';
import { Search } from '../../components/Header/components';
import { Block, Button, FileInput, SelectInput, Text, TextInput } from '../../components/layout';
import ButtonAction from '../../components/Table/components/ButtonAction';
import { SvgIconEdit, SvgIconModalDelete, SvgIconTrash } from '../../svgs';
import { store } from '../../utils/store';
import validateFormSchema from '../../utils/validateFormSchema';
import ImagesInput from './components/ImagesInput';

function Products() {
    const { language } = useContext(store);
    const paginate = useRef(false);
    const [search, setSearch] = useState("");
    const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);
    const [modalEditData, setModalEditData] = useState(null);
    const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(null);

    const [result, setResult] = useState({ edges: [], pageInfo: { hasNextPage: false }, totalCount: 0 });

    const [queryProducts, { loading }] = useLazyQuery(
        gql`
            query products($first: Int, $after: String, $search: String) {
                products(first: $first, after: $after, search: $search) {
                    edges {
                        node {
                            id
                            name
                            description
                            images
                            price
                            status
                        }
                        cursor
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                    totalCount
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.products) {
                    setResult({
                        edges: paginate.current ? [...result.edges, ...data.products.edges] : data.products.edges,
                        pageInfo: data.products.pageInfo,
                        totalCount: data.products.totalCount
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const [mutationProductCreate, { loading: loadingProductCreate }] = useMutation(
        gql`
            mutation productCreate($name: String!, $description: String, $price: Float!, $images: Json, $status: ProductStatus!) {
                productCreate(name: $name, description: $description, price: $price, images: $images, status: $status) {
                    id
                    name
                    description
                    price
                    images
                    status
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.productCreate) {
                    setModalCreateIsOpen(false);
                    requestData();
                }
            }
        }
    );

    const [mutationProductEdit, { loading: loadingProductEdit }] = useMutation(
        gql`
            mutation productUpdate($id: ID!, $name: String, $description: String, $price: Float, $images: Json, $status: ProductStatus) {
                productUpdate(id: $id, name: $name, description: $description, price: $price, images: $images, status: $status) {
                    id
                    name
                    description
                    price
                    images
                    status
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.productUpdate) {
                    setModalEditData(null);
                    let index = result.edges.findIndex(product => product.node.id === data.productUpdate.id);
                    if (index !== -1) {
                        result.edges[index].node = data.productUpdate;
                        setResult(...result);
                    }
                }
            }
        }
    );

    const [mutationProductDelete, { loading: loadingProductDelete }] = useMutation(
        gql`
            mutation productDelete($id: ID!) {
                productDelete(id: $id) {
                    id
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.productDelete) {
                    const index = result.edges.findIndex((edge) => edge.cursor == data.productDelete.id);
                    if (index !== -1) {
                        result.edges.splice(index, 1);
                        setModalDeleteIsOpen(null);
                        setResult([...data]);
                    }
                }
            }
        }
    );

    const requestData = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        queryProducts({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined
            }
        });
    }

    useEffect(() => {
        requestData();
    }, [language]);

    useEffect(() => {
        requestData();
    }, [search]);

    return (
        <>
            <Block flex height="100%">
                <Header
                    leftChildren={<Search onChange={(e) => setSearch(e.target.value)} />}
                    rightChildren={<Button style={{ minWidth: 309 }} text="Add product" onClick={() => setModalCreateIsOpen(true)} />}
                />
                <Block height="100%" mr={20} ml={20} style={{ overflow: "hidden" }}>
                    <Table
                        loading={loading}
                        columns={[
                            { label: "Name", key: "name" },
                            { label: "Price", key: "price" },
                            { label: "Status", key: "status" }
                        ]}
                        rows={result.edges.map((edge) => ({ ...edge.node, actions: <><ButtonAction icon={<SvgIconEdit />} text="Edit" onClick={() => setModalEditData(edge.node)} /><ButtonAction icon={<SvgIconTrash />} onClick={() => setModalDeleteIsOpen(edge.cursor)} /></> }))}
                        next={() => requestData({ next: true, after: result.pageInfo.endCursor })}
                        hasMore={result.pageInfo.hasNextPage}
                    />
                </Block>
            </Block>
            <Modal
                title="Create product"
                isOpen={modalCreateIsOpen}
                onRequestClose={() => setModalCreateIsOpen(false)}
            >
                <Text size={14} height={20}>Products</Text>
                <Block mt={28}>
                    <Formik
                        initialValues={{}}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required(),
                                    description: yup.string(),
                                    price: yup.number().required(),
                                    images: yup.mixed(),
                                    status: yup.string().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationProductCreate({ variables: values });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Block>
                                        <Block>
                                            <TextInput name="name" label="Name" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.name} />
                                        </Block>
                                        <Block mt={12}>
                                            <TextInput name="description" label="Description" type="text" onChange={handleChange} onBlur={handleBlur} error={errors.description} />
                                        </Block>
                                        <Block mt={12}>
                                            <TextInput name="price" label="Price" type="number" min="0" step="0.01" onChange={handleChange} onBlur={handleBlur} error={errors.price} />
                                        </Block>
                                        <Block mt={12}>
                                            <ImagesInput name="images" label="Images" value={values.images} onChange={handleChange} onBlur={handleBlur} error={errors.images} />
                                        </Block>
                                        <Block mt={12}>
                                            <SelectInput label="Status" name="status" onBlur={handleBlur} error={errors.status} options={[{ label: "Published", value: "PUBLISHED" }, { label: "Draft", value: "DRAFT" }]} />
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingProductCreate} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>

            <Modal
                title="Edit product"
                isOpen={!!modalEditData}
                onRequestClose={() => setModalEditData(null)}
            >
                <Text size={14} height={20}>Products</Text>
                <Block mt={28}>
                    <Formik
                        enableReinitialize
                        initialValues={modalEditData}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={values => {
                            return validateFormSchema(
                                yup.object().shape({
                                    name: yup.string().required(),
                                    description: yup.string(),
                                    price: yup.number().required(),
                                    image: yup.mixed(),
                                    status: yup.string().required()
                                }),
                                values
                            )
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            mutationProductEdit({ variables: values });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Block>
                                        <Block>
                                            <TextInput name="name" label="Name" type="text" value={values.name} onChange={handleChange} onBlur={handleBlur} error={errors.name} />
                                        </Block>
                                        <Block mt={12}>
                                            <TextInput name="description" label="Description" type="text" value={values.description} onChange={handleChange} onBlur={handleBlur} error={errors.description} />
                                        </Block>
                                        <Block mt={12}>
                                            <TextInput name="price" label="Price" type="number" min="0" step="0.01" value={values.price} onChange={handleChange} onBlur={handleBlur} error={errors.price} />
                                        </Block>
                                        <Block mt={12}>
                                            <ImagesInput name="images" label="Images" value={values.images} onChange={handleChange} onBlur={handleBlur} error={errors.images} />
                                        </Block>
                                        <Block mt={12}>
                                            <SelectInput label="Status" name="status" onBlur={handleBlur} error={errors.status} options={[{ label: "Published", value: "PUBLISHED" }, { label: "Draft", value: "DRAFT" }]} />
                                        </Block>
                                        <Block mt={44}>
                                            <Button type="submit" text="Submit" loading={loadingProductEdit} />
                                        </Block>
                                    </Block>
                                </form>
                            )
                        }}
                    </Formik>
                </Block>
            </Modal>

            <Modal
                width={280}
                title="Delete product"
                isOpen={!!modalDeleteIsOpen}
                onRequestClose={() => setModalDeleteIsOpen(null)}
            >
                <Block mb={20}>
                    <SvgIconModalDelete />
                </Block>
                <Text size={18} height={24}>Are you sure that you want to delete this product?</Text>
                <Text size={12} height={14} mt={8}>This action it’s irreversible, it will erase all the data from this product.</Text>
                <Block mt={32}>
                    <Button text="Delete" loading={loadingProductDelete} onClick={() => mutationProductDelete({ variables: { id: modalDeleteIsOpen } })} />
                </Block>
            </Modal>
        </>
    );
}

export default Products;