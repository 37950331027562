import * as React from "react"

function IconPlus(props) {
    return (
        <svg
            width={10}
            height={10}
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M9 5H1M5 9V1" stroke="#fff" strokeLinecap="square" />
        </svg>
    )
}

export default IconPlus
