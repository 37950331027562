import * as React from "react"

function IconLogout(props) {
    return (
        <svg
            width={14}
            height={14}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M9.907 1.375A6 6 0 114 1.428"
                stroke="#fff"
                strokeLinejoin="round"
            />
            <path d="M7 1.375V7.75" stroke="#fff" />
        </svg>
    )
}

export default IconLogout
