import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { SvgIconMoveDown, SvgIconMoveUp, SvgIconSettings, SvgIconTextLine, SvgIconTrash } from '../../../../../svgs';
import colors from '../../../../../utils/colors';
import { deepEqual } from '../../../../../utils/methods';
import { Block, CheckBox, Text, TextInput } from '../../../../layout';

function Element({
    path,
    title,
    type,
    fieldKey,
    properties = {},
    onMoveUp,
    onMoveDown,
    onDelete,
    onChange,
    onBlur
}) {
    const [isOpen, setIsOpen] = useState(false);

    const node = useRef();
    const handleClick = e => {
        if (node.current.contains(e.target)) {
            return;
        }
        setIsOpen(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    return (
        <Wrapper color="white">
            <Block>
                <Settings pointer onClick={() => setIsOpen(true)}>
                    <SvgIconSettings color={colors.greyDark} />
                </Settings>
                <Block pl={24} pr={24} pt={16} pb={16}>
                    <Text size={14} height={20} mb={16}>{title}</Text>
                    <Block row>
                        <Block mr={12}>
                            <TextInput label="Key" name={`${path}.key`} value={fieldKey} onChange={onChange} onBlur={onBlur} />
                        </Block>
                        <Block>
                            <TextInput label="Title" name={`${path}.title`} value={title} onChange={onChange} onBlur={onBlur} />
                        </Block>
                    </Block>
                </Block>
                <Block width="100%" height={1} color="greyAlmostWhite" />
                <Block pl={24} pr={24} pt={18} pb={20} row>
                    {type && <Block mr={16}>
                        <TextInput label="Type" name={`${path}.properties.type`} value={properties.type} onChange={onChange} onBlur={onBlur} />
                    </Block>}
                    <Block>
                        <CheckBox label="Required" name={`${path}.properties.required`} value={properties.required} onChange={onChange} onBlur={onBlur} />
                    </Block>
                </Block>
            </Block>
            <List ref={node} show={isOpen.toString()} color="white" width={180}>
                <Item row pt={16} pb={16} pl={24} pr={24} center onClick={onDelete}>
                    <SvgIconTrash />
                    <Text size={14} height={20} ml={10}>Delete</Text>
                </Item>
                <Block width="100%" height={1} color="greyAlmostWhite" />
                <Item row pt={16} pb={16} pl={24} pr={24} center onClick={() => { onMoveUp && onMoveUp(); setIsOpen(false); }}>
                    <SvgIconMoveUp />
                    <Text size={14} height={20} ml={10}>Move up</Text>
                </Item>
                <Block width="100%" height={1} color="greyAlmostWhite" />
                <Item row pt={16} pb={16} pl={24} pr={24} center onClick={() => { onMoveDown && onMoveDown(); setIsOpen(false); }}>
                    <SvgIconMoveDown />
                    <Text size={14} height={20} ml={10}>Move down</Text>
                </Item>
            </List>
        </Wrapper>
    );
}

const Wrapper = styled(Block)`
    position: relative;
`;

const Settings = styled(Block)`
    position: absolute;
    top: 18px;
    right: 18px;
`;

const List = styled(Block)`
    position: absolute;
    top: 0;
    right: -210px;
    opacity: ${({ show }) => show === "true" ? 1 : 0};
    visibility: ${({ show }) => show === "true" ? "visible" : "hidden"};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
`;

const Item = styled(Block)`
    cursor: pointer;
`;

export default React.memo(Element, (prevProps, nextProps) => {
    return prevProps.path === nextProps.path
        && prevProps.title === nextProps.title
        && prevProps.fieldKey === nextProps.fieldKey
        && deepEqual(prevProps.properties, nextProps.properties);
});