import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { SvgIconClose, SvgIconMoveDown, SvgIconSettings, SvgIconMoveUp, SvgIconTrash, SvgIconEdit, SvgIconUp, SvgIconDown } from '../../../../../svgs';
import colors from '../../../../../utils/colors';
import { Block, Text, TextInput } from '../../../../layout';

function Section({
    path,
    sectionKey,
    title,
    children,
    onMoveUp,
    onMoveDown,
    onDelete,
    onChange,
    onBlur
}) {
    const [isChildrenOpen, setIsChildrenOpen] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);

    const node1 = useRef();
    const node2 = useRef();
    const handleClick = e => {
        if (node1.current.contains(e.target)) {
            return;
        }
        if (node2.current.contains(e.target)) {
            return;
        }
        setIsOpen(false);
        setIsEditOpen(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    return (
        <Wrapper>
            <Header row center space="between" mt={12} mb={12}>
                <Text size={24} height={32} bold>{title}</Text>
                <Block row>
                    <Block mr={8} pointer onClick={() => setIsOpen(true)}>
                        <SvgIconSettings color={colors.greyDark} />
                    </Block>
                    <Block pointer onClick={() => setIsChildrenOpen(!isChildrenOpen)}>
                        {isChildrenOpen ? <SvgIconUp /> : <SvgIconDown />}
                    </Block>
                </Block>
            </Header>
            <Block width="100%" height={1} color="greyLight" mb={8} />
            <Children open={isChildrenOpen}>
                {children}
            </Children>
            <List ref={node1} show={isOpen.toString()} color="white" width={180}>
                <Item row pt={16} pb={16} pl={24} pr={24} center onClick={onDelete}>
                    <SvgIconTrash />
                    <Text size={14} height={20} ml={10}>Delete</Text>
                </Item>
                <Block width="100%" height={1} color="greyAlmostWhite" />
                <Item row pt={16} pb={16} pl={24} pr={24} center onClick={() => { onMoveUp && onMoveUp(); setIsOpen(false); }}>
                    <SvgIconMoveUp />
                    <Text size={14} height={20} ml={10}>Move up</Text>
                </Item>
                <Block width="100%" height={1} color="greyAlmostWhite" />
                <Item row pt={16} pb={16} pl={24} pr={24} center onClick={() => { onMoveDown && onMoveDown(); setIsOpen(false); }}>
                    <SvgIconMoveDown />
                    <Text size={14} height={20} ml={10}>Move down</Text>
                </Item>
                <Block width="100%" height={1} color="greyAlmostWhite" />
                <Item row pt={16} pb={16} pl={24} pr={24} center onClick={() => { setIsOpen(false); setIsEditOpen(true); }}>
                    <SvgIconEdit />
                    <Text size={14} height={20} ml={10}>Edit</Text>
                </Item>
            </List>
            <Edit ref={node2} show={isEditOpen.toString()} color="white" width={480}>
                <Block height={52} pt={10} pl={24}>
                    <Text size={18} height={32}>Edit Section</Text>
                    <Block pointer center middle color="greyDark" width={53} height={53} style={{ position: "absolute", top: 0, right: 0 }} onClick={() => setIsEditOpen(false)}>
                        <SvgIconClose />
                    </Block>
                </Block>
                <Block width="100%" height={1} color="greyAlmostWhite" />
                <Block pt={24} pb={24} pl={24} pr={24}>
                    <Block>
                        <TextInput label="Key" name={`${path}.key`} value={sectionKey} onChange={onChange} onBlur={onBlur} />
                    </Block>
                    <Block mt={12}>
                        <TextInput label="Title" name={`${path}.title`} value={title} onChange={onChange} onBlur={onBlur} />
                    </Block>
                </Block>
            </Edit>
        </Wrapper>
    );
}

const Wrapper = styled(Block)`
    position: relative;
`;

const Children = styled(Block)`
    overflow: ${({ open }) => open ? "visible" : "hidden"};
    height: ${({ open }) => open ? "auto" : "0px"};
`;

const Header = styled(Block)`
    position: relative;
`;

const List = styled(Block)`
    position: absolute;
    top: 0;
    right: -210px;
    opacity: ${({ show }) => show === "true" ? 1 : 0};
    visibility: ${({ show }) => show === "true" ? "visible" : "hidden"};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
`;

const Item = styled(Block)`
    cursor: pointer;
`;

const Edit = styled(Block)`
    position: absolute;
    top: 0;
    right: -500px;
    opacity: ${({ show }) => show === "true" ? 1 : 0};
    visibility: ${({ show }) => show === "true" ? "visible" : "hidden"};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
`;

export default Section;