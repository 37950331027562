import * as React from "react"

function IconContentTypes(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M14 4H4M3 4H2M6 8H5M6 12H5M14 8H7M14 12H7"
                stroke="#5D6368"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default IconContentTypes
