import React, { forwardRef } from 'react';
import { Block, Text } from '../../../layout';

function Row({ actionsWidth, type, rowIndex, row, columns, children }, actionsRef) {
    return (
        <Block>
            <Block row height={52} pl={24} color="white" center>
                {columns.map((column, columnIndex) => {
                    return (
                        <Block key={columnIndex} flex maxWidth={column.options && column.options.width}>
                            <Text size={14} height={20}>{row[column.key]}</Text>
                        </Block>
                    );
                })}
                <Block right width={actionsWidth} row ref={rowIndex === 0 ? actionsRef : undefined}>
                    {row.actions}
                </Block>
            </Block>
            {children}
            <Block width="100%" height={0} mb={type === 1 ? 8 : 0} />
            {type === 2 && <Block width="100%" height={1} color="greyAlmostWhite" />}
        </Block>
    );
}

export default forwardRef(Row);