import * as React from "react"

function IconTemplates(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M13 14v-2H3v2M13 2v2H3V2M3 10h10V6H3v4z" stroke="#5D6368" />
        </svg>
    )
}

export default IconTemplates
