import * as React from "react"

function IconTrash(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M4 5.429h8V14H4V5.43z" stroke="#35383B" />
            <path
                d="M9 7.143v5.143M8 2v2M7 7.143v5.143M12 3.714H4"
                stroke="#35383B"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default IconTrash
