import * as React from "react"

function IconList(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 4H4M3 4H2M3 8H2M3 12H2M14 8H4M14 12H4"
        stroke="#35383B"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconList
