import * as React from "react"

function IconMoveDown(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11 10l-3 3-3-3M8 12.5V3" stroke="#35383B" />
    </svg>
  )
}

export default IconMoveDown
