import * as React from "react"

function IconArrowLeft(props) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M7 11L4 8l3-3M4 8h9" stroke="#000" />
        </svg>
    )
}

export default IconArrowLeft
